header{
  color:white;
  .header-navbar .navbar-header .navbar-brand{
    padding-top: 7px;

  }
  .brand-logo{
    max-height:45px;
  }
}

.menu-collapsed{
  header {
    .navbar-brand{
      padding: 0 !important;
    }
    .header-navbar .navbar-header .navbar-brand {
      padding: 15px 0 !important;
      .brand-logo {
        max-width: 66px;
      }
    }
  }
}
  // Simple
*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
*::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0,0,0,0.1);
  }
*::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: rgba(0,0,0,0.2);
  }
*::-webkit-scrollbar-thumb:hover{
  	background: rgba(0,0,0,0.4);
  }
*::-webkit-scrollbar-thumb:active{
  	background: rgba(0,0,0,.9);
  }
  

.__react_component_tooltip{
  max-width:500px;
  text-transform: initial;
  font-weight: initial;
  letter-spacing: initial;
}

.max-image{
  max-width: 500px;
  max-height: 500px;
}