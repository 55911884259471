div.result {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  p {
    margin-bottom: 10px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: bold;
    line-height: 30px;
    color: #00b5b8;
    .measure{display: block;
      font-size: 20px;}
  }
  .moyen{
    font-size: 12px;
    color: grey;
    font-style: italic;
  }
}