.list-group {
  border-top: 1px solid #E4E7ED;

  .list-group-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
     &:last-child{
       border-bottom-width:1px !important;
     }
    .button-container {
      i {
        font-size: 1.2rem;
        margin-left: 0.4rem;
      }
    }
  }
}
.onHover:hover {
  color: #505356;
}