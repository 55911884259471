.calculs {
  table {
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    border: 0.5px solid #E3EBF3;
    thead tr th {
      position: relative;

      &.sortable{
        cursor:pointer;
      }
      .sort-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        right: 9px;
        top: 33%;
        span {
          display: flex;
          height: 7px;
          align-items: center;
          justify-content: center;
          opacity: 0.5;
          &.disabled{
            opacity: 0;
          }
          &.active{
            color:#009C9F;
          }
        }
      }
    }
  }
}

.tableFixHead          { 
  overflow: auto; 
  max-height: 70vh; 
}
.tableFixHead thead th { 
  position: sticky!important; 
  top: 0; 
  z-index: 1; 
  background-color: #fff; 
  border-top: 1px solid #E3EBF3; 
  border-bottom: 1px solid #E3EBF3;
}

