.calcul-raw{
  .button-container{
    .disabled{
      opacity:0.5
    }
    cursor:pointer;
    display: flex;
    i{font-size:1.5rem;}
    &:hover{opacity: 1}
  }
}