
.card-columns{
  @media (min-width: 576px) {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  @media (min-width: 992px) {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  @media (min-width: 1200px) {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
  .card-title{
    margin-bottom: 10px;
  }
}

.card-colums-tutorial {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}



.fdes-mere-container {
  overflow-y: auto;
  overflow-x: hidden;
}