
.card-columns{
  @media (min-width: 576px) {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  @media (min-width: 992px) {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  @media (min-width: 1200px) {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0);
  .card-title{
    margin-bottom: 10px;
  }
}

.grid-hover .list-group {
  position: absolute;
  width: 100%;
  top: 0;
  min-height:100%;
  background: rgba(255, 255, 255, 0.5);
  .list-group-item{
    padding:0.25rem;
    display: flex;
    align-items: center;
    img{
      flex: 1 1 25%;
      max-width: 30%;
      margin-right: 10px;
      max-height: 65px;
    }
  }
}
$firstBreakWidth: "all and (max-width: 1600px)";

.family-product-container{
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}


.grid-hover figure.effect-sarah figcaption{
  @media #{$firstBreakWidth} {
    padding:1rem;
  }
  h2{
    @media #{$firstBreakWidth} {
     font-size:1.5rem;
    }
  }
  div p {
    @media #{$firstBreakWidth} {
      font-size:0.8rem;
    }
  }
}
