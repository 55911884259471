.thermometer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;

  .help-info {
    position: absolute;
    top: 0;
    right: 0;
  }

  .colorBlocks {
    display: flex;
    width: 20px;
    background: #a49d9d;
    height: 100%;
    position: relative;
    flex-direction: column;

    .red {
      background: #c00000
    }

    .orange {
      background: #ed7d31
    }

    .green {
      background: #92d050
    }

    .bar {
      background: #404040;
      display: block;
      width: 35px;
      height: 10px;
      border-radius: 5px;
      position: absolute;
      margin: 0;
      left: -7px;
      transition: bottom 1s ease, transform 1s;

      .bar-content {
        position: absolute;
        top: -21px;
        margin: 0;
        left: -92px;
        padding-right: 10px;

        .value {
          display: block;
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
    }

    .jaugeMax {
      position: absolute;
      top: -8px;
      left: 32px;
      margin: 0;
      color: #a8a8a8;
      font-size: 0.8rem;
    }

    .jaugeMin {
      position: absolute;
      bottom: -8px;
      left: 32px;
      margin: 0;
      color: #a8a8a8;
      font-size: 0.8rem;
    }

    .jaugeAvg {
      position: absolute;
      left: 32px;
      margin: 0;
      color: #a8a8a8;
      font-size: 0.8rem;
    }
  }
}