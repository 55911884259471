.modal-container{
  position: fixed;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  .background{
    width: 100%;
    height: 100%;
    position: absolute;
    background: #96969685;
  }
}