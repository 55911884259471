table.module {

  width: 100%;
  font-size: 0.8rem;
  thead tr th {
    border: 1px solid #898b8a;
    padding: 0.25rem;
    white-space: normal;
    min-width:75px;
    max-width:75px;
    text-align: center;
    &.indicator-head{
      min-width:150px;
      max-width:150px;
      text-align: left;
      padding-left:10px;
      width:8%;
    }
    &.unity{
      min-width:75px;
      max-width:75px;
      width:15%;

    }
  }

  tbody tr td {
    border: 1px solid #898b8a;
    padding: 0.25rem;

    &.group{
      font-weight: bold;
      padding: 0.75rem;
      cursor:pointer;
    }
  }
}