.fdes .col {
  @media (min-width: 1200px) {
    height: calc(95vh - 57px);
    .margin-scroll:last-of-type {
      margin-bottom: 150px;
    }
    .card {
      margin-right: 15px;
    }
    .sticky-card {
      margin-right: 15px;
    }
  }
  .card {
    .card-header {
      border-bottom: 1px solid rgba(0, 0, 0, .06);

      .icon-file {
        opacity: 0.5;
        margin-right: 15px;
        margin-top: 10px;

        &.active {
          cursor: pointer;
          opacity: 1;
        }
      }
    }
  }

  .sticky-card {
    margin-bottom: 1rem;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;

    &.sticky-card-bottom {
      top: inherit;
      bottom: 0;
    }

    .refresh-button-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        font-size: 12px;
        display: flex;
        align-items: center;
        text-transform: initial;
        letter-spacing: initial;
      }
    }
  }

  .badge-white-title {
    font-weight: 400;
    background-color: #ffffff;
    color: #00b5b8;
    font-size: 1.5rem;
    margin-right: 0.5rem;
    width: 35px;
    height: 35px;
  }

  img.bordered {
    border: 1px solid #404e676b;
    border-radius: 5px;
  }

  .form-group {
    .button-container {
      overflow: auto;
    }
  }
}

.slider-container {
  .noUi-target {
    background-color: #E4E9F2;
    border: none;
    box-shadow: none;
    height: 10px;
    margin-bottom: 10px;
    z-index: 0;
  }

  .noUi-horizontal .noUi-handle {
    border-radius: 50%;
    background: #FFF;
    width: 20px;
    height: 20px;
    top: -5px;
    right: -17px;
    left: auto;

    &:before {
      display: none !important;;
    }

    &:after {
      display: none !important;;
    }
  }
}
